<template>
  <div class="home">
    <headerNav></headerNav>
    <div class="header">
      <div class="header_center">
        <h1 @clikc="goIndex">榆阳产业服务平台营销商城</h1>
        <p>个人中心</p>
        <div>
          <el-input>
            <el-button slot="append">搜索</el-button>
          </el-input>
        </div>
      </div>
      <!-- <img src="" alt="">
      <span>榆阳产业服务平台营销商城</span> -->
    </div>

    <div class="person_content">
      <div class="person_content_left">
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          background-color="#fff"
          text-color="#333"
          active-text-color="#ffd04b"
        >
        <!-- @open="handleOpen"
          @close="handleClose" -->
          <div v-for="(item, index) in menuList" :key="index">
            <el-submenu :index="item.id">
              <template slot="title">
                <span>{{ item.name }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  v-for="(it, inx) in item.children"
                  :key="inx"
                  :index="it.id"
                  @click="goMenu(it)"
                  >{{ it.name }}</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
          </div>
        </el-menu>
      </div>

      <div class="person_content_right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import headerNav from "./../../components/headerNav";
// @ is an alias to /src
export default {
  name: "home",
  data() {
    return {
      menuList: [
        {
          name: "账号设置",
          id: 1,
          children: [
            {
              name: "我的订单",
              id: 2,
              url: "myOrder",
            },
            {
              name: "收货地址",
              id: 3,
              url: "shippingSddress",
            },
            {
              name: "商品收藏",
              id: 4,
              url: "follow",
            },
            {
              name: "我的足迹",
              id: 5,
              url: "eootprint",
            },
            {
              name: "发票管理",
              id: 6,
              url: "bill"
            }
          ],
        },
      ],
      formInline: {},
    };
  },
  components: {
    headerNav,
  },
  mounted() {},
  methods: {
    goIndex() {
      this.$router.push({
        path: '/',
      })
    },
    showCatalog() {
      // this.tag =
    },
    goMenu(row) {
      this.$router.push({
        name: row.url,
      });
    },
    goList() {},
  },
};
</script>
<style scoped>
.home {
  text-align: left;
}

.header {
  width: 100%;
  height: 80px;
  background: #84c754;
}
.header_center {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  line-height: 80px;
}
.header_center > h1 {
  font-size: 28px;
  font-weight: 500;
  color: #ffffff;
  margin-right: 33px;
}
.header_center > p {
  color: #fff;
}
.header_center > div {
  margin-left: auto;
}
.person_content {
  width: 1200px;
  margin: 20px auto 0;
  display: flex;
}
.person_content_left {
  width: 160px;
  margin-right: 20px;
}
.home >>> .el-menu-item {
  padding: 0 30px;
  min-width: 50px;
}
.person_content_right {
  flex: 1;
  flex-shrink: inherit;
}

.el-menu-item:hover {
  background-color: #EFFAE8  !important;
}
.is-active {
  color: #84c754 !important;
}
</style>